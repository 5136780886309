<template>
  <div class="device">
    <div class="device_head">
      <span style="color: #b2b2b2">设备管理 /</span>
      <span style="color: #5c5c5c"> 设备列表</span>
    </div>
    <div class="device_wrapper">
      <div class="device_search">
        <div class="device_search_left">
          <div class="de_search_name de_search">
            <el-input
              v-model="searchForm.likeString"
              placeholder="设备名称、云序列号"
              class="department_search_inp"
              style="width: 200px"
              @keyup.enter.native="onSearch"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon"
              @click="onSearch"
            />
          </div>
          <!-- <el-select
            clearable
            v-model="searchForm.online"
            placeholder="设备状态"
            @change="filterStatus"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <el-select
            clearable
            v-model="searchForm.type"
            placeholder="设备类型"
            @change="onDevice"
          >
            <el-option
              v-for="item in category"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="device_add">
          <el-button type="primary" icon="el-icon-plus" @click="createdevice"
            >添加设备</el-button
          >
          <el-button @click="batchDelete">批量解绑</el-button>
        </div>
      </div>
      <div class="device_table_wrapper">
        <el-table
          ref="multipleTable"
          :data="deviceList"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          height="100%"
          class="device_table common_text common_t"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div class="nodata_text">无数据</div>
            </div>
          </template>
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="设备名称"
            align="left"
            width="120"
          >
          </el-table-column>
          <el-table-column prop="sn" label="云序列号" align="left" width="150">
          </el-table-column>
          <el-table-column
            prop="type"
            label="设备类型"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.type == 0">指纹系列考勤机</div>
              <div v-if="scope.row.type == 1">近红外人脸考勤机</div>
              <div v-if="scope.row.type == 2">动态人脸考勤机</div>
              <div v-if="scope.row.type == 3">近红外人脸门禁机</div>
              <div v-if="scope.row.type == 4">动态人脸门禁机</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="安装位置"
            align="left"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="设备状态"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.isoffOnline" class="d_start_using">
                  离线
                </div>

                <div v-else class="d_forbidden">在线</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastUpdateTime"
            label="最近连机时间"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              {{
                scope.row.lastUpdateTime ? scope.row.lastUpdateTime : "--"
              }}</template
            >
          </el-table-column>
          <el-table-column label="操作" align="left" width="200">
            <template slot-scope="scope">
              <div class="operate">
                <span @click="editDevice(scope.row)"> 编辑 </span>
                <div @click="todeleteConfirm(scope.row)">解绑</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="pages.pageNum > 0"
        class="depagination"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="deagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 添加设备 -->
    <el-drawer
      size="473px"
      :visible.sync="dialogDevice"
      custom-class="dialog_device common_drawer"
      direction="rtl"
      :showClose="false"
      :wrapperClosable="false"
    >
      <span slot="title" class="dialog_title">
        <i class="el-icon-close icon-close" @click="cancelDevice"></i>
        <span class="dialog_t">{{
          deviceForm.id ? "编辑设备" : "添加设备"
        }}</span>
        <div class="dialog_confirm">
          <el-button
            class="add-confirm_a"
            @click="cancelDevice"
            style="background: none"
            >取 消</el-button
          >
          <el-button type="primary" @click="deviceAdd" class="add-confirm_a"
            >确 认</el-button
          >
        </div>
      </span>
      <el-form
        class="form_device"
        :model="deviceForm"
        :key="deviceKey"
        label-position="left"
        ref="addForm"
        :rules="arules"
      >
        <el-form-item label="设备名称" prop="name">
          <el-input
            v-model="deviceForm.name"
            maxlength="20"
            style="width: 413px"
          ></el-input>
        </el-form-item>
        <el-form-item label="云序列号" prop="sn">
          <el-input
            :disabled="!!deviceForm.id"
            v-model="deviceForm.sn"
            maxlength="16"
            style="width: 413px; text-transform: uppercase"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="type">
          <el-select
            v-model="deviceForm.type"
            disabled
            style="width: 413px"
            placeholder="填写云序列号自动选择"
          >
            <el-option
              v-for="item in category"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装位置" prop="address">
          <el-input
            v-model="deviceForm.address"
            maxlength="50"
            style="width: 413px"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 删除设备提示 -->
    <el-dialog
      title=""
      :visible.sync="errorTips"
      width="33%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>确认解绑</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="cloaseDelte"
        />
      </div>
      <div class="delectdiglog">
        <div class="delectdiglog_title" v-if="deteid">
          确定要解绑该设备（{{ detesn }}）吗？
        </div>
        <div v-else class="delectdiglog_title">确定要解绑所选设备吗</div>
      </div>

      <div class="diglog_operation">
        <el-button @click="cloaseDelte">取消</el-button>
        <el-button type="primary" @click="deleteDevice">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../utils/http";
export default {
  components: {},
  data() {
    var validateIDCard = (rule, value, callback) => {
      if (!value || value.length !== 16) {
        callback(new Error("非法的云序列号"));
      } else {
        callback();
      }
    };
    return {
      mineStatusValue: [],
      deviceForm: {}, //设备表单
      searchForm: {}, //搜索的设备
      multipleSelection: [], //选择的数据
      selectIds: [], //处理过选择的设备id
      deviceList: [], //设备列表
      dialogDevice: false, //添加设备
      deviceKey: Math.random(),
      detesn: "", //所选的sn
      deteid: null, //所选的设备id
      errorTips: false, //确认删除
      searchicon: false, //搜索图标
      newArr: [], //
      searchpeo: false,
      category: [
        {
          label: "指纹系列考勤机",
          value: 0,
        },
        {
          value: 1,
          label: "近红外人脸考勤机",
        },
        {
          value: 2,
          label: "动态人脸考勤机",
        },
        {
          value: 3,
          label: "近红外人脸门禁机",
        },
        {
          value: 4,
          label: "动态人脸门禁机",
        },
      ],
      statusList: [
        {
          value: true,
          label: "在线",
        },
        {
          value: false,
          label: "离线",
        },
      ],
      arules: {
        name: [{ required: true, message: "必填", trigger: "blur" }],
        sn: [
          { required: true, message: "请输入云序列号", trigger: "blur" },
          { validator: validateIDCard, trigger: "blur" },
        ],
      },
      pages: {}, //分页
      currentPage: 1, //当前页
    };
  },

  watch: {
    "searchForm.likeString": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV) {
          if (newV == "") {
            delete this.searchForm.likeString;
            this.getDevice({
              pageNum: 1,
            });

            this.searchicon = false;
          } else {
            this.searchicon = true;
          }
        } else {
          delete this.searchForm.likeString;
          this.getDevice({
            pageNum: 1,
          });
          this.searchicon = false;
        }
      },
      immediate: true,
    },
    "deviceForm.sn": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV) {
          const firstTwoChars = newV.slice(0, 2).toUpperCase();
          this.deviceForm.sn = firstTwoChars + newV.slice(2);

          this.determineType(this.deviceForm.sn);
        } else {
          this.deviceForm.type = null;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getDevice({ pageNum: 1 });
    if (this.$refs.addForm) this.$refs.addForm.resetFields();
  },

  methods: {
    getDevice(form) {
      http
        .get(
          "/dmsDevice/list",
          Object.assign(form, { pageSize: 50 }, this.searchForm)
        )
        .then((res) => {
          if (res.code == 200) {
            res.data.list.map((item) => {
              if (item.lastUpdateTime) {
                item.isoffOnline = this.judgeGobeyond(item.lastUpdateTime);
              } else {
                item.isoffOnline = true;
              }
            });
            this.deviceList = res.data.list;
            this.pages = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 页数改变
    changePage(e) {
      this.currentPage = e;
      this.getDevice({
        nameAndSNLike: this.nameAndSNLike,
        pageNum: e,
      });
    },
    onSearch() {
      if (!this.searchForm.likeString) {
        return;
      }
      this.getDevice({ pageNum: 1 });
    },
    onDevice() {
      this.getDevice({ pageNum: 1 });
    },
    // 判断类型
    determineType(str) {
      var type1 = str.startsWith("NI", 0);
      var type2 = str.startsWith("AI", 0);
      var type3 = str.startsWith("FP", 0);
      var type4 = str.startsWith("NP", 0);
      if (type1 || type2 || type3 || type4) {
        if (type1 || type4) {
          this.deviceForm.type = 1;
        }
        if (type2) {
          this.deviceForm.type = 2;
        }
        if (type3) {
          this.deviceForm.type = 0;
        }
      } else {
        delete this.deviceForm.type;
      }
    },
    // 清除输入框
    clearup() {
      this.searchForm.likeString = "";
      delete this.searchForm.likeString;
      this.getDevice({
        pageNum: 1,
      });
    },
    // 判断是否超过5分钟
    judgeGobeyond(string) {
      // 给定的时间字符串
      const givenTimeString = string;

      // 将给定的时间字符串转换为Date对象
      const givenTime = new Date(givenTimeString);

      // 获取当前时间
      const currentTime = new Date();

      // 计算时间差，单位为毫秒
      const timeDifference = currentTime - givenTime;

      // 将时间差转换为分钟
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      if (timeDifferenceInMinutes > 5) {
        return true;
      } else {
        return false;
      }
    },
    clearPeo() {
      this.peopleForm.name = "";
      this.peopleList({
        deviceId: this.deviceId,
        pageNum: 1,
      });
    },
    searchPeople(n) {
      let content;
      if (n == 0) {
        content = {
          deviceId: this.deviceId,
        };
        this.peopleForm.peopleType = null;
        this.peopleForm.departmentId = null;
        this.selectTreeName = "";
        this.peopleForm.name = "";
      }
      if (n == 1) {
        content = {
          deviceId: this.deviceId,
          name: this.peopleForm.name,
        };
        this.peopleForm.peopleType = null;
        this.peopleForm.departmentId = null;
        this.selectTreeName = "";
      }
      if (n == 2) {
        content = {
          deviceId: this.deviceId,
          departmentId: this.peopleForm.departmentId,
        };
        this.peopleForm.peopleType = null;
        this.peopleForm.name = "";
      }
      if (n == 3) {
        content = {
          deviceId: this.deviceId,
          peopleType: this.peopleForm.peopleType,
        };
        this.peopleForm.name = "";
        this.peopleForm.departmentId = null;
        this.selectTreeName = "";
      }
      this.peopleList(content);
    },
    // 添加设备
    createdevice() {
      this.deviceKey = Math.random();
      this.$set(this.deviceForm, "name", "考勤机");
      setTimeout(() => {
        this.dialogDevice = true;
      }, 200);
    },
    searchNestedObject(array, targetValue) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (item.a === targetValue) {
          // 如果找到目标值，返回当前对象
          return item;
        }
        if (Array.isArray(item.children)) {
          // 如果对象有子数组，递归搜索子数组
          const result = this.searchNestedObject(item.children, targetValue);
          if (result) {
            // 如果在子数组中找到目标值，返回整个路径
            return {
              ...item,
              children: [result], // 只包含找到目标值的子路径
            };
          }
        }
      }
      return null; // 如果没有找到目标值，返回null
    },
    // 编辑设备
    editDevice(value) {
      var object = JSON.stringify(value);
      this.deviceForm = JSON.parse(object);
      setTimeout(() => {
        this.dialogDevice = true;
      }, 200);
    },
    // 取消弹窗
    cancelDevice() {
      this.dialogDevice = false;
      this.deviceForm = {};
      if (this.$refs.addForm) this.$refs.addForm.resetFields();
    },
    // 确认添加设备
    deviceAdd() {
      if (this.deviceForm.type == null) {
        this.$message.error("请输入正确的云序列号");
        return;
      }
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          var object = JSON.stringify(this.deviceForm);
          object = JSON.parse(object);

          // 处理多余的东西
          var hadleForm = {};
          hadleForm.name = object.name;
          hadleForm.sn = object.sn;
          hadleForm.type = object.type;
          hadleForm.address = object.address;
          if (object.id) {
            hadleForm.id = object.id;
            http.post("/dmsDevice/update", hadleForm).then((res) => {
              if (res.code == 200) {
                this.$message.success("编辑成功");
                this.getDevice({ pageNum: 1 });
                this.cancelDevice();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            http.post("/dmsDevice/add", hadleForm).then((res) => {
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.getDevice({ pageNum: 1 });
                this.cancelDevice();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    // 删除二次确认弹窗
    batchDelete() {
      if (this.selectIds.length <= 0) {
        this.$message.error("请先勾选要解绑的设备");
      } else this.errorTips = true;
    },
    // 取消删除二次弹窗
    cloaseDelte() {
      this.errorTips = false;
      this.deteid = null;
      this.detesn = "";
    },
    //确认删除操作
    todeleteConfirm(item) {
      this.errorTips = true;
      this.deteid = item.id;
      this.detesn = item.sn;
    },
    //删除设备
    deleteDevice() {
      var arr = [];
      if (this.deteid) {
        arr = [this.deteid];
      } else {
        arr = this.selectIds;
      }

      http
        .post("/dmsDevice/delete", {
          ids: arr,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("解绑成功");
            this.errorTips = false;
            this.$refs.multipleTable.clearSelection();
            this.getDevice({ pageNum: 1 });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 页数改变
    chagePage(e) {
      //  let obj = { ...this.prangge, ...this.peopleForm, ...this.totaltime };
      this.peopleList(
        Object.assign(this.peopleForm, {
          deviceId: this.deviceId,
          pageNum: e,
        })
      );
    },
    cancelClose() {
      this.dialogClosetime = false;
      this.closeBegin = null;
      this.closeEnd = null;
    },
    cancelSettime() {
      this.dialogSettime = false;
      this.openBegin = null;
      this.openEnd = null;
    },
    handleSelectionChange(val) {
      this.selectIds = [];
      this.multipleSelection = val;
      val.map((item) => {
        this.selectIds.push(item.id);
      });
    },
  },
};
</script>
<style scoped>
.device {
  width: 100%;
  padding: 16px 20px;
  background: #f4f7fc;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.device_head {
  font-size: 14px;
  color: #5c5c5c;
}

.device_wrapper {
  width: calc(100vw - 290px);
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.device_search {
  display: flex;
  padding: 20px 20px 18px 20px;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.de_search_name {
  width: 280px !important;
}

.de_search {
  width: 243px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.de_search:focus-within {
  border-color: #0096fa;
}

.title-text {
  font-size: 20px;
}

.refresh-right {
  margin-left: 10px;
  margin-top: 10px;
}

::v-deep .el-icon-refresh-right:before {
  font-size: 18px;
}

.device_search_w {
  display: flex;
  width: 100%;
}

::v-deep .device_search_d .el-input {
  height: 38px;
  border-radius: 4px;
  /* border: 1px solid #d0d0d0; */
}

::v-deep .device_search_d .el-input__inner {
  height: 40px;
  border: 1px solid #d0d0d0;
}

.device_search_b {
  border: none;
  padding-left: 0;
  padding-right: 10px;
  background: none;
}

.device_search_b:hover {
  background: none;
}

.device_add {
  margin-left: auto;
}

.device_table_wrapper {
  padding: 20px 18px 20px 20px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  flex: 1;
}
.device_table {
  position: absolute;
  width: 97%;
  height: 98%;
  font-size: 16px !important;
}

.d_start_using {
  width: 62px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #f0142f;
}

.d_forbidden {
  color: #0caf7b;
  width: 62px;
  text-align: center;
  font-size: 14px;
}

.operate {
  padding: 0 !important;
}

.operate span {
  cursor: pointer;
  color: #0058ff;
  margin-right: 20px;
}

.operate div {
  display: inline-block;
  cursor: pointer;
  color: #0058ff;
}

.dialog_title {
  display: flex;
  align-items: center;
  color: #1a1a1a;
}

.dialog_t {
  font-size: 18px;
  margin-left: 20px;
}

.form_device {
  margin-left: 30px;
  margin-top: 30px;
}

.dialog_confirm {
  margin: 0 0 0 auto;
  font-size: 15px;
}

.add-confirm {
  background: #0058ff;
  font-size: 15px;
  width: 120px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.add-confirm_a {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.table_people {
  width: auto;
  margin: 5px 0 0 5px;
  font-size: 16px;
  overflow: auto;
}

.pagination_p {
  text-align: right;
}

::v-deep .dialog_people {
  padding: 0 !important;
}

::v-deep .dialog_people .el-dialog__body {
  padding: 20px 30px 0px 30px;
}

.time_picker {
  display: flex;
  justify-content: space-around;
  line-height: 50px;
  font-size: 16px;
}

.set_workday {
  margin: 24px 30px 20px 30px;
  width: 100%;
}

.set_workday_checkbox {
  display: inline-block;
  text-align: right;
  width: 70%;
}

.set_workday_checkgroup {
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  font-size: 16px;
  width: 80%;
  margin-top: 10px;
}

.time_picker2 {
  display: flex;
  justify-content: space-between;
  line-height: 45px;
  font-size: 16px;
  width: 80%;
  margin-top: 5px;
}

.people_button {
  margin-right: 0;
  margin-left: auto;
  display: block;
  /* width: 150px; */
}

.people_button_a {
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #0058ff;
  text-align: center;
}

.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}

.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}

.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.department_n {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}

.people_button_p {
  background: #e0ecff;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  color: #0058ff !important;
  text-align: center;
  border: none;

  height: 26px;
}

.depagination {
  display: flex;
  height: 50px;
  margin-top: 34px;
  justify-content: space-between;
}

.deagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .depagination .el-pagination {
  margin: 0 20px 30px auto;
}

::v-deep .el-select__tags-text {
  color: #0058ff;
}

::v-deep .el-tag.el-tag--info .el-tag__close {
  background-color: #0058ff;
  color: #e0ecff;
}

::v-deep .el-tag {
  width: 64px;
  background-color: #e0ecff !important;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

::v-deep .common_text .el-table__empty-block {
  text-align: left;
  width: 100% !important;
}
::v-deep .el-table__empty-text {
  width: 20%;
}

.nodata {
  margin-top: 0;
}

.nodata_text {
  width: 120px;
  text-align: center;
}

.icon-close {
  font-size: 24px;
  cursor: pointer;
  color: #7e84a3;
}

.setstyle {
  overflow-x: auto;
  height: 100%;
  width: 100%;
}

::v-deep .el-select-dropdown__item {
  padding: 0;
}

::v-deep .el-tree-node__content {
  height: 50px;
}

/* ::v-deep .el-popper {
  width: 13.5rem;
}
::v-deep .el-date-editor {
  width: 13.5rem;
} */
/*  .el-time-spinner__item {
  font-size: 20px !important;
  color: #0058ff;
} */
/* ::v-deep .el-time-panel__footer>.el-time-panel__btn {
    font-size: 16px !important;
  } */

::v-deep .el-table {
  overflow: auto;
}
::v-deep .el-table::-webkit-scrollbar {
  display: none;
}
/* ::v-deep .el-table__body-wrapper {
  overflow: visible;
} */
::v-deep .el-table::after {
  position: relative;
}

::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}
.device_search_left {
  display: flex;
  align-items: center;
}
::v-deep .device_search .el-select {
  margin-left: 10px;
}
::v-deep .el-input__inner {
  border-color: #d0d0d0;
}
</style>