<template>
  <div class="staff">
    <div class="staff_head">
      <div class="staff_l">
        <img
          src="~@/assets/structure/close.png"
          class="staff_close"
          @click="close"
        />
        <div>{{ title }}</div>
      </div>
      <div class="staff_r" v-if="!disabled">
        <el-button @click="close">取消</el-button>
        <el-button class="staff_f_comfin" type="primary" @click="comfin"
          >确认</el-button
        >
      </div>
      <div class="staff_r" v-else>
        <el-button @click="edit" type="primary">编辑</el-button>
      </div>
    </div>

    <!-- 填写内容 -->
    <div class="staff_information">
      <el-upload
        :disabled="disabled"
        ref="upload"
        class="el-upload__input"
        style="display: inline-block"
        action="https://jsonplaceholder.typicode.com/posts/"
        :show-file-list="false"
        :http-request="httpRequest"
        :before-upload="beforeAvatarUpload"
      >
        <img
          class="staff_img"
          src="@/assets/structure/head.png"
          v-if="!isUpload"
        />
        <div v-else>
          <img class="staff_img" :src="imgUrl" v-if="!disabled" />
          <el-image
            class="staff_img"
            fit="cover"
            :src="imgUrl"
            v-else
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </el-upload>

      <el-form
        ref="employees"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="员工工号" prop="jobNumber">
              <el-input v-model="formLabelAlign.jobNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="设备工号" prop="deviceNumber">
              <el-input
                v-model="formLabelAlign.deviceNumber"
                maxlength="9"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="部门">
              <el-select
                style="width: 100%"
                ref="multiSelect"
                v-model="selectTreeName"
                placeholder="请选择"
                :popper-append-to-body="false"
              >
                <el-option
                  :value="selectTree"
                  class="setstyle"
                  style="overflow: auto; height: 100%"
                  disabled
                >
                  <el-tree
                    style="min-height: 150px; max-height: 300px"
                    :data="depamentList"
                    :props="defaultProps"
                    ref="tree"
                    check-strictly
                    :expand-on-click-node="false"
                    @node-click="
                      (data, node, item) =>
                        addAdminHandleNodeClick(data, node, item)
                    "
                  ></el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="入职日期" prop="timeJoin">
              <el-date-picker
                v-model="formLabelAlign.timeJoin"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker
                v-model="formLabelAlign.birthday"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="11">
            <el-form-item
              label="性别"
              prop="sex"
              v-if="formLabelAlign.sex || !disabled"
            >
              <el-select
                v-model="formLabelAlign.sex"
                placeholder="请选择"
                :disabled="disabled"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="sex" v-else>
              <el-input v-model="sexName" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="formLabelAlign.sex" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="学历" prop="education">
              <el-select
                v-model="formLabelAlign.education"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in educationalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="籍贯" prop="nativePlace">
              <el-cascader
                clearable
                size="large"
                :options="city"
                :props="props"
                separator=""
                v-model="formLabelAlign.nativePlace"
              >
              </el-cascader>
              <!-- <el-input
                v-model="formLabelAlign.nativePlace"
                :disabled="disabled"
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="地址" prop="address">
              <el-input v-model="formLabelAlign.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="formLabelAlign.phone" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="E-mail" prop="mail">
              <el-input v-model="formLabelAlign.mail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="company_information">
          <el-row class="form_layout">
            <el-col :span="11" class="form_layout_c">
              <el-form-item label="身份证号" prop="idCard">
                <el-input
                  v-model="formLabelAlign.idCard"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="职位" prop="job">
                <el-input v-model="formLabelAlign.job"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row class="form_layout">
          <el-col :span="11" class="form_layout_c">
            <el-form-item label="卡号" prop="jobCard">
              <el-input v-model="formLabelAlign.jobCard"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="设备密码">
              <el-input
                v-model="formLabelAlign.devicePassword"
                autocomplete="new-password"
                type="password"
                show-password
                maxlength="6"
                oninput="value=value.replace(/[^\d]/g, '')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="order_informaiton">
          <el-row class="form_layout">
            <el-col :span="11" class="form_layout_c">
              <el-form-item label="车牌号" prop="carNumber">
                <el-input
                  v-model="formLabelAlign.carNumber"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="备注" prop="remark">
                <el-input v-model="formLabelAlign.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
import axios from "axios";
import city from "../../../../utils/city";
export default {
  data() {
    //身份证号格式校验
    var isCardId = (rule, value, callback) => {
      if (value) {
        const reg =
          /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);
        if (!card) {
          callback(new Error("身份证号格式有误！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
      callback();
    };
    // 禁止输入中文
    var validateInput = (rule, value, callback) => {
      if (value && /[\u4e00-\u9fa5]/g.test(value)) {
        callback(new Error("不允许输入中文"));
      } else {
        callback();
      }
    };
    // 限制只能输入数字和英文
    const validateJob = (rule, value, callback) => {
      const regex = /^[0-9a-zA-Z]*$/;
      if (!regex.test(value)) {
        callback(new Error("只能输入数字和英文字符"));
      } else {
        callback();
      }
    };
    return {
      title: "添加员工", //标题
      type: 0, //0是添加，1是编辑
      srcList: [], //大图预览
      ossArguments: {}, //oss参数
      imgUrl: "", //头像
      isUpload: false,
      editPhoneDialog: false, //更改手机号
      editPhone: "", //要更改的手机号
      formLabelAlign: {
        jobDepartment: "",
        status: 2,
      }, //表单内容
      formLabelAlignCopy: {}, //复制
      depamentList: [], //部门列表
      city: city, //城市列表
      props: {
        value: "name", // 显示的值字段名
        label: "name", // 显示的文本字段名
        children: "children", // 子级字段名
      },
      disabled: false, //禁用按钮
      disabledDepartment: false, //禁用部门
      disableDeviceNumber: false, //禁用设备工号
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      selectTreeName: "", //选择的部门
      sexName: "", //性别名称
      selectTree: "",
      options: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      educationalList: [
        {
          label: "小学",
          value: "小学",
        },
        {
          label: "初中",
          value: "初中",
        },
        {
          label: "高中",
          value: "高中",
        },
        {
          label: "专科",
          value: "专科",
        },
        {
          label: "本科",
          value: "本科",
        },
        {
          label: "研究生",
          value: "研究生",
        },
        {
          label: "博士",
          value: "博士",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        jobNumber: [
          {
            required: true,
            message: "请输入员工工号",
            trigger: "blur",
          },
          {
            validator: validateInput,
            trigger: "blur",
          },
          {
            pattern: /^[^\W_]+$/,
            message: "不允许输入特殊字符",
            trigger: "blur",
          },
        ],
        deviceNumber: [
          {
            required: true,
            message: "请输入正确的设备工号",
            trigger: "blur",
          },
          {
            pattern: /^[1-9]\d*$/,
            message: "只能输入数字且从1开始",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: false,
            trigger: "blur",
            message: "请输入正确的身份证",
            validator: isCardId,
          },
        ],
        mail: [
          {
            type: "email",
            required: false,
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        timeJoin: [
          {
            required: true,
            message: "请选择入职日期",
            trigger: "change",
          },
        ],
        jobCard: [
          {
            required: false,
            trigger: "blur",
            validator: validateJob,
          },
        ],
      },
    };
  },

  props: ["id", "deparment"],

  created() {
    // 获取部门列表
    var arr = localStorage.getItem("department");
    var arr2 = JSON.parse(arr);
    this.depamentList = arr2;

    // 获取上传参数
    this.getOssparameter();

    // 如果存在id,证明是编辑员工信息
    if (this.id && this.id > 0) {
      this.title = "编辑员工";

      this.type = 1;

      this.getUserinfo(this.id);
    }
  },

  watch: {
    formLabelAlign: {
      handler: function (val, oldval) {
        if (val.sex == 1) {
          this.sexName = "男";
        } else if (val.sex == 0) {
          this.sexName = "女";
        }
      },
      deep: true,
    },
  },

  methods: {
    // 关闭弹窗
    close() {
      this.common();
      this.$emit("close");
    },

    // 获取当天日期
    setDate() {
      let currentDate = new Date();

      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");

      let formattedDate = `${year}-${month}-${day}`;
      this.$set(this.formLabelAlign, "timeJoin", formattedDate);
    },

    // 关闭公共
    common() {
      this.$refs.employees.resetFields();
      this.isUpload = false;
      this.imgUrl = "";
      this.selectTreeName = "";
      this.disabledDepartment = false;
      this.disableDeviceNumber = false;
      this.disabled = false;
    },

    // 获取上传参数
    getOssparameter() {
      http
        .get("/oss/policy", {
          type: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.ossArguments = res.data;
          }
        });
    },

    // 获取最大可用设备号
    getDevicenum() {
      http.get("/umsUser/get/max_device_number").then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            this.$set(this.formLabelAlign, "jobNumber", res.data);
            this.$set(this.formLabelAlign, "deviceNumber", res.data);
          }, 200);
        }
      });
    },

    // 限制图片
    beforeAvatarUpload(file) {
      //限制类型
      const fileName = file.name; //取文件名字
      const fileType = fileName.substring(fileName.lastIndexOf(".")); //去文件后缀
      // jpeg,.png,.jpg,
      if (fileType === ".jpg" || fileType === ".png" || fileType === ".jpeg") {
        // 不处理
      } else {
        this.$message.error("请上传正确的格式的图片");
        return false;
      }
    },

    // 上传
    httpRequest(files) {
      var file = files.file;
      const imageConversion = require("image-conversion");
      console.log("压缩前" + file.size / 1024 + "K");
      //阿里云OSS上传
      var name = Date.now() + file.name;
      let param = new FormData(); // 创建form对象

      var url = this.ossArguments.host;
      param.append("key", this.ossArguments.dir + file.name); //key 唯一值  即相对路径
      param.append("policy", this.ossArguments.policy); //服务器返回的policy
      param.append("OSSAccessKeyId", this.ossArguments.accessKeyId); //服务器返回的accessId
      param.append("signature", this.ossArguments.signature); //服务器返回的signature
      param.append("name", file.name); //文件名
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      if (file.size > 1024 * 1024) {
        imageConversion.compressAccurately(file, 1024).then((res) => {
          axios
            .post(url, param, config)
            .then((response) => {
              this.isUpload = true;
              this.imgUrl =
                this.ossArguments.host +
                "/" +
                this.ossArguments.dir +
                file.name;
              this.formLabelAlign.headUrl = this.imgUrl;
            })
            .catch((err) => {
              this.$message.error("页面停留过长,请重新打开弹窗");
            });
        });
      } else {
        param.append("file", file, name);
        axios
          .post(url, param, config)
          .then((response) => {
            this.isUpload = true;
            this.imgUrl =
              this.ossArguments.host + "/" + this.ossArguments.dir + file.name;
            this.formLabelAlign.headUrl = this.imgUrl;
          })
          .catch((err) => {
            this.$message.error("页面停留过长,请重新打开弹窗");
          });
      }
    },

    // 选择上级部门点击方法
    addAdminHandleNodeClick(e) {
      this.selectTreeName = e.name;
      this.formLabelAlign.jobDepartment = e.id;
      this.$refs.multiSelect.blur();
    },

    // 新增员工
    comfin() {
      this.$refs["employees"].validate((valid) => {
        if (valid) {
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);
          if (value.jobPassword) {
            if (value.jobPassword.length != 6) {
              this.$message.error("密码为6位数字");
              return;
            }
          }

          for (const key in value) {
            if (value.hasOwnProperty(key)) {
              // 检查值是否为空字符串
              if (value[key] === "") {
                // 如果值为空字符串，删除该键
                if (key === "phone") {
                  delete value[key];
                } else {
                  value[key] = " ";
                }
              }
            }
          }
          if (value.deviceNumber) {
            value.deviceNumber = parseInt(value.deviceNumber);
          }
          if (value.nativePlace) {
            if (
              value.nativePlace instanceof Array &&
              value.nativePlace.length > 0
            ) {
              value.nativePlace = value.nativePlace.join("");
            } else {
              value.nativePlace = " ";
            }
          }

          if (this.formLabelAlignCopy.nativePlace) {
            if (
              this.formLabelAlignCopy.nativePlace instanceof Array &&
              this.formLabelAlignCopy.nativePlace.length > 0
            ) {
              this.formLabelAlignCopy.nativePlace =
                this.formLabelAlignCopy.nativePlace.join("");
            }
          }

          // 添加员工
          if (this.type == 0) {
            value.timeJoin = value.timeJoin + " 00:00:00";
            value.active = 1;
            http.post("/umsUser/add", value).then((res) => {
              if (res.code == 200) {
                this.common();
                this.$message.success("添加成功");
                this.$emit("comfinEmploy");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            var object = {};
            for (var key in value) {
              if (
                value.hasOwnProperty(key) &&
                this.formLabelAlignCopy.hasOwnProperty(key) &&
                this.formLabelAlignCopy[key] !== value[key]
              ) {
                console.log(key);
                object[key] = value[key];
              }
            }
            for (var key in value) {
              if (
                value.hasOwnProperty(key) &&
                !this.formLabelAlignCopy.hasOwnProperty(key)
              ) {
                object[key] = value[key];
              }
            }
            object.userUpdateType = "NORMAL";
            object.status = 2;
            object.ids = [this.formLabelAlignCopy.id];
            if (object.timeJoin) {
              object.timeJoin = object.timeJoin + " 00:00:00";
            }
            if (object.phone) {
              delete object.phone;
            }

            if (!this.formLabelAlignCopy.devicePassword) {
              if (object.devicePassword == " ") {
                delete object.devicePassword;
              }
            } else {
              if (
                this.formLabelAlignCopy.devicePassword !=
                  object.devicePassword &&
                object.devicePassword == " "
              ) {
                this.$message.error("设备密码只能更改，不能删除");
                return;
              }
            }

            // 编辑员工
            http.post("/umsUser/update", object).then((res) => {
              if (res.code == 200) {
                this.common();
                this.$message.success("入职成功");
                this.$emit("comfinEmploy");
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },

    // 获取员工信息
    getUserinfo(id) {
      http
        .get("/umsUser/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.deparment) {
              this.selectTreeName = this.deparment;
            }
            this.formLabelAlign = res.data;
            if (res.data.headUrl) {
              this.isUpload = true;
              this.imgUrl = res.data.headUrl;
              this.srcList = [res.data.headUrl];
            }

            // 如果没有部门
            if (!res.data.jobDepartment) {
              delete this.formLabelAlign.jobDepartment;
              this.formLabelAlign.jobDepartment = 0;
            } else {
              this.disabledDepartment = true;
            }

            // 如果有设备工号
            if (res.data.deviceNumber) {
              this.disableDeviceNumber = true;
            } else {
              this.getDevicenum();
            }

            // 如果有身份证
            if (res.data.idCard == " ") {
              delete res.data.idCard;
            }

            // 如果有邮箱
            if (res.data.mail == " ") {
              delete res.data.mail;
            }

            // 如果有设备密码
            if (res.data.devicePassword && res.data.devicePassword == " ") {
              delete res.data.devicePassword;
            }

            if (res.data.nativePlace) {
              var provinceName = "";
              var cityName = "";
              // 遍历所有选项
              for (const provinceOption of this.city) {
                // 检查字符串是否以省份名称开头
                if (res.data.nativePlace.startsWith(provinceOption.name)) {
                  provinceName = provinceOption.name;
                  // 去掉省份名称后的剩余部分
                  cityName = res.data.nativePlace.substring(
                    provinceOption.name.length
                  );
                  break;
                }
              }
              var arr = [];
              if (provinceName) {
                arr.push(provinceName);
              }
              if (cityName) {
                arr.push(cityName);
              }
              this.formLabelAlign.nativePlace = arr;
            }

            this.formLabelAlignCopy = JSON.stringify(this.formLabelAlign);
            this.formLabelAlignCopy = JSON.parse(this.formLabelAlignCopy);

            // 如果没有入职日期
            this.setDate();
          }
        });
    },
    // 编辑员工信息
    edit() {
      this.disabled = false;
      this.title = "编辑员工";
    },

    // 取消编辑手机号
    cancelEditphone() {
      this.editPhone = "";
      this.editPhoneDialog = false;
    },
    // 获取当天日期
    setDate() {
      let currentDate = new Date();

      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");

      let formattedDate = `${year}-${month}-${day}`;
      this.$set(this.formLabelAlign, "timeJoin", formattedDate);
    },
    // 获取最大可用设备号
    getDevicenum() {
      http.get("/umsUser/get/max_device_number").then((res) => {
        if (res.code == 200) {
          if (res.data < 1000000000) {
            setTimeout(() => {
              this.$set(this.formLabelAlign, "jobNumber", res.data);
              this.$set(this.formLabelAlign, "deviceNumber", res.data);
            }, 200);
          }
        }
      });
    },

    // 确认编辑手机号
    comfinEditphone() {
      this.editPhone = "";
      this.editPhoneDialog = false;
    },
  },
};
</script>

<style scoped>
.staff_head {
  height: 89px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cfd4e8;
}

.staff_close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.staff_l {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1a1a1a;
}

.staff_information {
  padding: 30px;
  margin-top: 89px;
  box-sizing: border-box;
  overflow-x: scroll;
}

.staff_img {
  width: 100px;
  height: 100px;
  text-align: center;
  object-fit: cover;
  line-height: 100px;
  border-radius: 50%;
}

.form_layout {
  width: 100%;
  margin-top: 30px;
}

.form_layout_c {
  margin-right: 30px;
}

.company_t {
  font-size: 18px;
  color: rgba(26, 26, 26, 0.4);
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #f4f4f4;
  color: #9a9a9a;
  border: 1px solid #d0d0d0;
}

::v-deep .el-select-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
}
::v-deep .el-form-item {
  margin: 0;
}
::v-deep .staff_information .el-form-item__label:before {
  color: #f0142f !important;
}

::v-deep .form_layout_c .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-cascader {
  width: 100%;
}
::v-deep .el-input-group__append {
  background: #f4f4f4;
}
.diglog_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 24px 0 24px;
  height: 73px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}
.diglog_icon_close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation_other {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.diglog_body {
  margin: 30px 0 40px;
  padding: 0 30px;
  box-sizing: border-box;
}
::v-deep .diglog_body .el-input {
  height: 56px;
}
::v-deep .diglog_body .el-input__inner {
  height: 56px;
  background: #f2f2f2;
}
::v-deep .diglog_operation_other .el-button {
  width: 100px;
  height: 40px;
  margin-right: 30px;
}
</style>
