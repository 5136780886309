<template>
  <div class="checkin">
    <div class="checkin_title">设备管理 / <span>设备用户列表</span></div>
    <div class="checkin_information">
      <div class="member_header">
        <div class="member_header_bto" @click="openavailableDialog">
          添加设备打卡人员
        </div>
        <div class="member_header_bto" @click="removeConfirm">
          删除设备打卡人员
        </div>
      </div>
      <div class="member_content">
        <div class="member_content_l">
          <div class="member_content_search">
            <div class="de_search_name de_search">
              <el-input
                v-model="searchDeviceForm.likeString"
                placeholder="搜索设备云序列号、名称"
                class="department_search_inp"
                style="width: 200px"
                @keyup.enter.native="getDevice"
              ></el-input>
              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                @click="clearDevice"
                v-if="searchDevice"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon"
                @click="getDevice"
              />
            </div>
            <el-select
              clearable
              v-model="isonline"
              @change="filterStatus"
              placeholder="设备状态"
            >
              <el-option
                v-for="item in deviceStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              v-model="searchDeviceForm.type"
              placeholder="设备类型"
              @change="getDevice"
            >
              <el-option
                v-for="item in category"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="member_content_device">
            <div
              v-for="item in deviceList"
              @click="selectDevice(item.id)"
              :class="[
                'member_content_d',
                deviceIds == item.id ? 'member_content_d_select' : '',
                item.isoffOnline ? 'offline' : 'online',
              ]"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="online_detection"
            v-if="deviceIds"
            @click="onlineDetection"
          >
            在线检测
          </div>
        </div>
        <div class="member_content_r">
          <div class="member_content_r_search">
            <div class="de_search_name de_search">
              <el-tooltip
                effect="dark"
                content="请输入姓名、员工工号、设备工号"
                placement="top-start"
              >
                <el-input
                  v-model="peopleSearchForm.likeString"
                  placeholder="姓名、员工工号、设备..."
                  class="department_search_inp"
                  style="width: 200px"
                  @keyup.enter.native="onSearch"
                ></el-input>
              </el-tooltip>
              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                v-if="searchicon"
                @click="clearPeople"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon"
                @click="onSearch"
              />
            </div>
            <el-date-picker
              type="date"
              placeholder="入职日期"
              v-model="date"
              @change="onSearch"
            >
            </el-date-picker>
          </div>
          <div class="memeber_table">
            <el-table
              height="100%"
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="getRowClass"
              @selection-change="handleSelectionChange"
              tooltip-effect="dark"
              class="m_table"
            >
              <template slot="empty">
                <div>
                  <img src="@/assets/nodata.png" class="nodata" />
                  <div>无数据</div>
                </div>
              </template>
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="150"
                fixed="left"
              >
                <template slot-scope="scope">
                  <div class="member_name" v-if="scope.row.umsUser">
                    <img
                      :src="scope.row.umsUser.headUrl"
                      class="member_head"
                      v-if="scope.row.umsUser.headUrl"
                    />
                    <img src="@/assets/head.png" v-else class="member_head" />
                    <div>{{ scope.row.umsUser.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="性别" width="80">
                <template slot-scope="scope">
                  <div v-if="scope.row.umsUser">
                    <div v-if="scope.row.umsUser.sex == 1">男</div>
                    <div v-if="scope.row.umsUser.sex == 0">女</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="员工工号" width="130">
                <template slot-scope="scope">
                  <div v-if="scope.row.umsUser">
                    <div>{{ scope.row.umsUser.jobNumber }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="设备工号" width="130">
                <template slot-scope="scope">
                  <div v-if="scope.row.umsUser">
                    <div>{{ scope.row.umsUser.deviceNumber }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="部门" width="130">
                <template slot-scope="scope">
                  <div v-if="scope.row.umsDepartment">
                    <div class="ellipsis">
                      {{ scope.row.umsDepartment.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="生物特征值" width="130">
                <template slot-scope="scope">
                  <div class="structure_feature" v-if="scope.row.umsUser">
                    <img
                      src="@/assets/structure/face.png"
                      class="feature"
                      v-if="scope.row.umsUser.dataFace"
                    />
                    <img
                      src="@/assets/structure/fingerprint.png"
                      class="feature"
                      v-if="
                        scope.row.umsUser.dataFinger &&
                        !isNull(scope.row.umsUser.dataFinger)
                      "
                    />
                    <img
                      src="@/assets/structure/vein.png"
                      class="feature"
                      v-if="scope.row.umsUser.dataHand"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="设备权限" width="120">
                <template slot-scope="scope">
                  <div v-if="scope.row.type == 0">-</div>
                  <div v-if="scope.row.type == 1">设备管理员</div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    style="margin-right: 10px"
                    v-if="scope.row.umsUser && !scope.row.umsUser.dataFace"
                    @click="checkIn(1, scope.row.user, scope.row.umsUser.id)"
                    >登记人脸</el-link
                  >
                  <el-link
                    type="primary"
                    style="margin-right: 10px"
                    v-else
                    @click="removeOperation(1, scope.row.user)"
                    >移除人脸</el-link
                  >
                  <el-link
                    type="primary"
                    style="margin-right: 10px"
                    @click="checkIn(3, scope.row.user, scope.row.umsUser.id)"
                    v-if="scope.row.umsUser && !scope.row.umsUser.dataHand"
                    >登记掌静脉</el-link
                  >
                  <el-link
                    type="primary"
                    style="margin-right: 10px"
                    @click="removeOperation(2, scope.row.user)"
                    v-else
                    >移除掌静脉</el-link
                  >
                  <el-link
                    v-if="isShowFingerprint"
                    type="primary"
                    style="margin-right: 10px"
                    @click="openFp(scope.row)"
                    >指纹管理</el-link
                  >
                  <el-link
                    type="primary"
                    v-if="
                      scope.row.type == 0 &&
                      (scope.row.umsUser.devicePassword ||
                        scope.row.umsUser.dataFace ||
                        !isNull(scope.row.umsUser.dataFinger) ||
                        scope.row.umsUser.dataHand)
                    "
                    style="margin-right: 10px"
                    @click="openAdminDialog(1, scope.row.user)"
                    >设置管理员</el-link
                  >
                  <el-link
                    type="primary"
                    v-if="scope.row.type == 1"
                    @click="openAdminDialog(2, scope.row.user)"
                    >移除管理员</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="newpagination" v-if="pages.total > 0">
            <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
            <el-pagination
              layout="prev, pager, next"
              :total="pages.total"
              :current-page="pages.pageNum"
              :page-size="50"
              @current-change="chagePage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 可用人员范围 -->
    <el-dialog
      title=""
      :visible.sync="availableDialog"
      width="45%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>可用人员范围</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeavailableDialog"
        />
      </div>
      <div class="available_frame">
        <div class="available_frame_left">
          <div class="available_title">部门/人员</div>
          <div class="available_search">
            <el-select v-model="selectType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchValue"
              @keyup.enter.native="searchAvailable"
              class="available_search_input"
              :placeholder="selectType == 1 ? '部门名称' : '员工姓名'"
            >
            </el-input>
            <img
              src="@/assets/structure/search.png"
              class="available_search_icon"
              @click="searchAvailable"
            />
          </div>
          <div>
            <div class="breadcrumbs">
              <div
                v-for="(item, index) in breadcrumbs"
                :key="index"
                class="breadcrumbs_all"
                @click="clickBreadcrumbs(index, item)"
              >
                <div
                  :class="[
                    'breadcrumbs_text',
                    index == breadcrumbs.length - 1
                      ? 'breadcrumbs_current'
                      : 'breadcrumbs_last',
                  ]"
                >
                  {{ item.name }}
                </div>
                <div style="margin: 0 5px">
                  {{ index == breadcrumbs.length - 1 ? " " : "/" }}
                </div>
              </div>
            </div>
            <div class="available_depaall">
              <div
                :class="[
                  'available_depa',
                  availableSelecDept == item.id ? 'available_depa_select' : '',
                ]"
                v-for="(item, index) in availableDept"
                @click="switchDpat(item)"
                :key="index"
              >
                <div class="available_depa_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div class="available_depa_left_name">{{ item.name }}</div>
                </div>
                <div
                  v-if="item.subDepartment"
                  class="available_depa_left_next"
                  @click="checkSubordinate(item)"
                >
                  下级
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="available_frame_center">
          <div class="available_title">待选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_top">
              <el-checkbox @change="selectAllpeople" v-model="isSelecAll"
                >全选</el-checkbox
              >
              <div>
                已选：{{
                  countCommonValues(waitPeopleList, completePeopleList)
                }}
              </div>
            </div>
            <div class="available_frame_tobeselected_center">
              <el-checkbox-group v-model="completePeopleList">
                <div
                  class="available_frame_tobeselected_single"
                  v-for="item in waitPeopleList"
                  :key="item.id"
                >
                  <el-checkbox
                    :label="item"
                    :checked="item.checked"
                    :disabled="item.isIn || !item.jobNumber"
                    ><br
                  /></el-checkbox>
                  <div class="available_depa_left_icon2">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department || item.umsDepartment.name }}
                    </div>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="available_frame_right">
          <div class="available_title">已选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_delte" @click="deleteAll">
              全部移除
            </div>
            <div class="available_frame_tobeselected_center">
              <div
                class="available_frame_tobeselected_single2"
                v-for="(item, index) in completePeopleList"
                :key="index"
              >
                <div class="available_frame_select_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department }}
                    </div>
                  </div>
                </div>
                <img
                  src="@/assets/close.png"
                  class="available_frame_select_right"
                  @click="deleteSelect(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="available_frame_bto">
        <div class="available_frame_cancel" @click="closeavailableDialog">
          取消
        </div>
        <el-button
          class="available_frame_comfin"
          @click="comfinAdd"
          v-loading="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 指纹管理 -->
    <el-dialog
      title=""
      :visible.sync="fpmanagement"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <div class="register_top">
        <div>指纹管理</div>
        <img
          src="@/assets/device/close.png"
          class="register_close"
          @click="closeFp"
        />
      </div>
      <div class="register_center">
        <div class="fp_list">
          <div class="fp_single" v-for="(item, index) in fpList" :key="index">
            <div class="fp_single_top">
              <img
                src="@/assets/device/fp.png"
                class="fp_single_icon"
                v-if="item && item != 'null'"
              />
              <img
                src="@/assets/device/add.png"
                class="fp_single_add"
                v-else
                @click="enterFp(index + 1)"
              />
            </div>
            <div class="fp_single_text">指纹{{ index + 1 }}</div>
            <img
              src="@/assets/device/delete.png"
              v-if="item && item != 'null'"
              class="deleteFp"
              @click.stop="deletefp(index)"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 登记 -->
    <el-dialog
      title=""
      :visible.sync="isRegisterDialog"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
    >
      <div class="register_top">
        <div>登记{{ registerTitle }}</div>
        <img
          src="@/assets/device/close.png"
          class="register_close"
          @click="closeRegister"
        />
      </div>
      <div class="register_center">
        <div class="register_center_name">
          请在设备上录入{{ registerTitle }}
        </div>
        <img
          src="@/assets/device/face.png"
          class="register_icon"
          v-if="registerTitle == '人脸'"
        />
        <img
          src="@/assets/device/fingerprint.png"
          class="register_icon"
          v-if="
            registerTitle == '指纹1' ||
            registerTitle == '指纹2' ||
            registerTitle == '指纹3'
          "
        />
        <img
          src="@/assets/device/vein.png"
          class="register_icon"
          v-if="registerTitle == '掌静脉'"
        />
        <div class="register_end" @click="closeRegister">结束录入</div>
      </div>
    </el-dialog>
    <!-- 设置为管理员 -->
    <el-dialog
      title=""
      :visible.sync="adminDialog"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>{{ adminTitle }}管理员</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="cancelAdminDialog"
        />
      </div>
      <div class="settips">确认将该员工{{ adminTitle }}设备管理员？</div>

      <div class="diglog_operation">
        <el-button @click="cancelAdminDialog">取消</el-button>
        <el-button type="primary" @click="comfinAdmin">确认</el-button>
      </div>
    </el-dialog>
    <!-- 删除人员弹窗 -->
    <el-dialog
      title=""
      :visible.sync="removeDialog"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>移除人员</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="removeDialog = false"
        />
      </div>
      <div class="settips">确认将所选员工移除出该设备？</div>

      <div class="diglog_operation">
        <el-button @click="removeDialog = false">取消</el-button>
        <el-button type="primary" @click="deleteavailablePeople"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../utils/http";
import Administrators from "./module/administrators.vue";
export default {
  data() {
    return {
      value1: null,
      onSubmit: false, //是否加载
      date: null, //入职日期筛选
      nameAndSNLike: "", //设备sn
      isShowFingerprint: false, //是否显示指纹管理
      searchicon: false, //是否显示
      searchDevice: false, //设备显示
      searchDeviceForm: {}, //设备搜索表单
      deviceIds: null, //当前设备id
      searchValue: "", //搜索关键词
      deviceList: [], //设备列表
      deviceListCopy: [], //复制设备列表
      selectType: 1, //搜索选择类型
      fpmanagement: false, //指纹管理
      isonline: "", //是否在线
      currentManagement: {}, //当前操作的管理人
      fpList: ["", "", ""], //指纹管理
      polling: null, //定时器
      options: [
        {
          label: "部门",
          value: 1,
        },
        {
          label: "人员",
          value: 2,
        },
      ],
      deviceStatus: [
        {
          label: "在线",
          value: 1,
        },
        {
          label: "离线",
          value: 2,
        },
      ],
      category: [
        {
          label: "指纹系列考勤机",
          value: 0,
        },
        {
          value: 1,
          label: "近红外人脸考勤机",
        },
        {
          value: 2,
          label: "动态人脸考勤机",
        },
        {
          value: 3,
          label: "近红外人脸门禁机",
        },
        {
          value: 4,
          label: "动态人脸门禁机",
        },
      ],
      availableDialog: false, //可用人员范围弹窗
      availableKey: Math.random(), //可用人员key
      removeDialog: false, //移除弹窗
      isSelecAll: false, //是否全选
      breadcrumbs: [], //面包屑
      availableDept: [], //部门列表
      availableDeptCopy: [], //复制部门列表
      availableSelecDept: null, //所选部门id
      peopleSearchForm: {}, //人员搜索表单
      peopleStaus: [
        {
          label: "在职",
          value: 1,
        },
        {
          label: "离职",
          value: 2,
        },
      ],
      waitPeopleList: [], //待选人员
      selectIds: [], //已选择人员
      completePeopleList: [], //已选人员
      adminId: null, //所选的人物id
      isRegisterDialog: false, //登记弹窗
      registerTitle: "", //登记文字
      adminDialog: false, //管理员弹窗
      adminTitle: "", //管理员文字
      tableData: [], //表格数据
      pages: {
        total: 0,
      }, //分页数据
    };
  },
  components: { Administrators },

  watch: {
    completePeopleList: {
      handler(newValue, oldValue) {
        if (this.waitPeopleList.length > 0) {
          var filteredArr = this.waitPeopleList.filter(function (item) {
            return !item.isIn;
          });
          // 当 items 数组发生变化时，这个函数会被调用
          let containsAll = filteredArr.every((value) =>
            newValue.includes(value)
          );
          // 你可以在这里执行你想要的操作
          if (containsAll) {
            this.isSelecAll = true;
          } else {
            this.isSelecAll = false;
          }

          var isrepeat = this.hasDuplicates(newValue);
          if (isrepeat) {
            var arr = this.removeDuplicates(newValue);

            this.completePeopleList = arr;
          }
        }
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    // 监听待选择列表
    waitPeopleList: {
      handler(newValue, oldValue) {
        this.completePeopleList.map((item) => {
          var index = newValue.findIndex((citem) => item.id == citem.id);
          if (index > -1) {
            this.$set(newValue[index], "checked", true);
          }
        });
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    // 监听设备sn变化
    deviceIds(newValue, oldValue) {
      if (newValue) {
        var value = this.deviceList.find((item) => item.id == newValue);
        if (value) {
          var type = value.sn.startsWith("NP", 0);
          if (!type) {
            this.isShowFingerprint = true;
          } else {
            this.isShowFingerprint = false;
          }
        }
      }
    },
    // 监听输入框变化
    searchValue(newValue, oldValue) {
      if (!newValue) {
        this.breadcrumbs = [];
        this.availableDept = this.availableDeptCopy;
        this.waitPeopleList = [];
      }
    },

    "peopleSearchForm.likeString": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV) {
          if (newV == "") {
            this.getDevicePeople({
              pageNum: 1,
            });

            this.searchicon = false;
          } else {
            this.searchicon = true;
          }
        } else {
          this.searchicon = false;
        }
      },
      immediate: true,
    },

    "searchDeviceForm.likeString": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV) {
          if (newV == "") {
            delete this.searchDeviceForm.likeString;
            this.getDevice();
            this.searchDevice = false;
          } else {
            this.searchDevice = true;
          }
        } else {
          this.searchicon = false;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getDevice();

    // 获取部门列表
    this.getDepartmentList();
  },

  methods: {
    // 获取设备列表
    getDevice() {
      http
        .get(
          "/dmsDevice/list",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 999,
            },
            this.searchDeviceForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              this.deviceIds = res.data.list[0].id;
              res.data.list.map((item) => {
                if (item.lastUpdateTime) {
                  item.isoffOnline = this.judgeGobeyond(item.lastUpdateTime);
                } else {
                  item.isoffOnline = true;
                }
              });

              this.getDevicePeople({
                pageNum: 1,
              });
            }
            this.deviceList = res.data.list;
            this.deviceListCopy = res.data.list;

            this.filterStatus(this.isonline);
          }
        });
    },
    // 检测设备列表
    checkDevice() {
      http
        .get(
          "/dmsDevice/list",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 999,
            },
            this.searchDeviceForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              this.deviceIds = res.data.list[0].id;
              res.data.list.map((item) => {
                if (item.lastUpdateTime) {
                  item.isoffOnline = this.judgeGobeyond(item.lastUpdateTime);
                } else {
                  item.isoffOnline = true;
                }
              });
            }
            this.deviceList = res.data.list;
            this.deviceListCopy = res.data.list;

            this.filterStatus(this.isonline);
          }
        });
    },
    // 获取部门列表
    getDepartmentList() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          var arr = this.convertToNestedStructure(res.data);
          this.availableDept = arr;
          this.availableDeptCopy = JSON.stringify(arr);
          this.availableDeptCopy = JSON.parse(this.availableDeptCopy);
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 获取部门列表
    getDept(id, department) {
      http
        .get("/dmsDevicePeople/search", {
          department: id,
          device: this.deviceIds,
        })
        .then((res) => {
          if (res.code == 200) {
            var arr = [];
            res.data.map((item) => {
              item.department = department;
              item.checked = false;
              arr.push(item);
            });
            this.waitPeopleList = arr;

            if (arr.length > 0) {
              var istrue = this.areAllIdsInArray(arr, this.completePeopleList);
              if (istrue) {
                this.isSelecAll = true;
              } else {
                this.isSelecAll = false;
              }
            }
          }
        });
    },
    // 切换设备
    selectDevice(id) {
      this.deviceIds = id;
      this.peopleSearchForm = {};
      this.selectIds = [];
      this.getDevicePeople({
        pageNum: 1,
      });
    },
    // 设备在线检测
    onlineDetection() {
      http
        .post("/dmsDevice/update/time", {
          id: this.deviceIds,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("检测成功");
            this.checkDevice();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 判断是否超过5分钟
    judgeGobeyond(string) {
      // 给定的时间字符串
      const givenTimeString = string;

      // 将给定的时间字符串转换为Date对象
      const givenTime = new Date(givenTimeString);

      // 获取当前时间
      const currentTime = new Date();

      // 计算时间差，单位为毫秒
      const timeDifference = currentTime - givenTime;

      // 将时间差转换为分钟
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      if (timeDifferenceInMinutes > 5) {
        return true;
      } else {
        return false;
      }
    },
    // 获取设备人员
    getDevicePeople(objcet) {
      http
        .get(
          "/dmsDevicePeople/list",
          Object.assign(
            {
              pageSize: 50,
              device: this.deviceIds,
            },
            objcet,
            this.peopleSearchForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.pages = res.data;
          }
        });
    },
    // 搜索
    onSearch() {
      if (this.date) {
        this.peopleSearchForm.timeJoin = this.formatDate(this.date);
        this.peopleSearchForm.timeJoin = encodeURI(
          this.peopleSearchForm.timeJoin
        );
      } else {
        delete this.peopleSearchForm.timeJoin;
      }
      this.getDevicePeople({
        pageNum: 1,
      });
    },
    // 标准时间转换
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 处理入职日期
    hadleQuit(value) {
      if (value) {
        var arr = value.split(" ");
        return arr[0];
      }
    },

    // 打开设备打卡人员弹窗
    openavailableDialog() {
      if (!this.deviceIds) {
        this.$message.error("请先选择设备");
        return;
      }
      this.availableKey = Math.random();
      this.searchValue = "";
      setTimeout(() => {
        this.availableDialog = true;
      }, 200);
    },
    // 取消设备打卡人员弹窗
    closeavailableDialog() {
      this.completePeopleList = [];
      this.breadcrumbs = [];
      this.availableDept = this.availableDeptCopy;
      this.isSelecAll = false;
      this.waitPeopleList = [];
      this.availableDialog = false;
    },
    // 确认添加打卡人员
    comfinAdd() {
      if (this.completePeopleList.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      this.onSubmit = true;
      var arr = [];
      this.completePeopleList.map((item) => {
        arr.push(item.id);
      });

      http
        .post("/dmsDevicePeople/add", {
          userList: arr,
          device: this.deviceIds,
        })
        .then((res) => {
          this.onSubmit = false;
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.closeavailableDialog();
            this.getDevicePeople({
              pageNum: 1,
            });
          }
        });
    },
    // 删除设备打卡人员
    deleteavailablePeople() {
      http
        .post("/dmsDevicePeople/delete", {
          ids: this.selectIds,
        })
        .then((res) => {
          this.removeDialog = false;
          if (res.code == 200) {
            this.$message.success("移除成功");
            this.getDevicePeople({
              pageNum: 1,
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    removeConfirm() {
      if (this.selectIds.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      this.removeDialog = true;
    },
    // 点击面包屑的部门
    clickBreadcrumbs(index, item) {
      this.breadcrumbs.splice(index + 1);
      var arr = this.searchNestedObject(this.availableDeptCopy, item.id);
      this.getDept(item.id, item.name);
      this.availableDept = arr.subDepartment;
    },
    // 查询子级
    searchNestedObject(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return arr[i];
        }
        if (arr[i].subDepartment) {
          const found = this.searchNestedObject(arr[i].subDepartment, id);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    },
    // 切换部门
    switchDpat(item) {
      this.availableSelecDept = item.id;
      this.getDept(item.id, item.name);
    },
    // 查看下级部门
    checkSubordinate(item) {
      this.breadcrumbs.push({
        name: item.name,
        id: item.id,
      });

      this.getDept(item.id, item.name);
      this.availableDept = item.subDepartment;
    },
    // 判断有几个已经选择
    countCommonValues(array1, array2) {
      // 将 array1 的 id 存储在一个集合中
      const set1 = new Set(array1.map((item) => item.id));

      // 计数器
      let commonCount = 0;

      // 遍历 array2 并检查每个 id 是否存在于集合中
      for (let item of array2) {
        if (set1.has(item.id)) {
          commonCount++;
        }
      }

      return commonCount;
    },
    // 判断待选择是否都在已选择里面
    areAllIdsInArray(a, b) {
      // 将 b 的 id 存储在一个集合中
      const setB = new Set(b.map((item) => item.id));

      // 检查 a 中的每个 id 是否都在集合 setB 中
      for (let item of a) {
        if (!setB.has(item.id)) {
          return false; // 如果有一个 id 不在 b 中，则返回 false
        }
      }

      return true; // 如果所有 id 都在 b 中，则返回 true
    },
    // 判断数组是否有重复
    hasDuplicates(array) {
      const set = new Set();
      for (let item of array) {
        if (set.has(item.id)) {
          return true; // 如果集合中已经存在该元素，则说明有重复
        }
        set.add(item.id); // 否则将元素添加到集合中
      }
      return false; // 遍历完数组后仍未发现重复项
    },
    // 数组去重
    removeDuplicates(array) {
      // 使用 Map 来去重，Map 的键是 id，值是对象本身
      const map = new Map();

      for (let item of array) {
        map.set(item.id, item); // 如果 id 已经存在，则会覆盖
      }

      // 将 Map 的值转换回数组
      return Array.from(map.values());
    },
    // 搜索
    searchAvailable() {
      if (this.selectType == 1) {
        // 搜索部门
        let result = this.findNode(this.searchValue);
        if (result) {
          this.availableDept = [result];
        } else {
          this.availableDept = [];
        }
      } else {
        // 搜索人
        http
          .get("/dmsDevicePeople/search", {
            device: this.deviceIds,
            userName: this.searchValue,
          })
          .then((res) => {
            if (res.code == 200) {
              this.waitPeopleList = res.data;
            }
          });
      }
    },
    // 搜索指定的值
    findNode(target) {
      let stack = [...this.availableDeptCopy]; // 初始化栈为顶层数组的元素

      while (stack.length > 0) {
        let node = stack.pop(); // 弹出栈顶元素

        if (node.name === target) {
          return node; // 找到目标节点，返回
        }

        if (node.subDepartment) {
          stack.push(...node.subDepartment); // 将子节点压入栈中
        }
      }

      return null; // 未找到目标节点
    },
    // 全选人员
    selectAllpeople(value) {
      if (value) {
        var filteredArr = this.waitPeopleList.filter(function (item) {
          return !item.isIn;
        });
        // 合并两个数组
        let combinedArray = this.completePeopleList.concat(filteredArr);

        // 使用 filter 方法去重
        let uniqueArray = combinedArray.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        this.completePeopleList = uniqueArray;
      } else {
        let result = this.completePeopleList.filter(
          (item) => !this.waitPeopleList.includes(item)
        );
        this.completePeopleList = result;
      }
    },

    // 删除选中的人员
    deleteSelect(index) {
      this.completePeopleList.splice(index, 1);
    },
    // 删除全部选中的人员
    deleteAll() {
      this.completePeopleList = [];
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 分页
    chagePage(page) {
      this.getDevicePeople({ pageNum: page });
    },

    // 表格多选
    handleSelectionChange(val) {
      this.selectIds = [];
      val.map((item) => {
        this.selectIds.push(item.id);
      });
    },

    // 打开指纹管理
    openFp(item) {
      this.currentManagement = item;
      this.fpmanagement = true;
      if (item.umsUser.dataFinger) {
        var arr = item.umsUser.dataFinger.split(",");
        this.fpList = arr;
      } else {
        this.fpList = ["", "", ""];
      }
    },

    // 关闭指纹管理
    closeFp() {
      this.currentManagement = {};
      this.fpList = [];
      this.fpmanagement = false;
    },

    // 打开指纹操作管理
    enterFp(index) {
      if (this.currentManagement) {
        this.fpmanagement = false;
        this.checkInFp(index, this.currentManagement.umsUser.id);
      }
    },

    // 登记指纹
    checkInFp(index, userid) {
      var type = "";
      switch (index) {
        case 1:
          type = "FP";
          this.registerTitle = "指纹1";
          break;
        case 2:
          type = "FP_SECOND";
          this.registerTitle = "指纹2";
          break;
        case 3:
          type = "FP_THIRD";
          this.registerTitle = "指纹3";
          break;
      }
      http
        .post("/dmsDevice/cmd", {
          cmdType: type,
          deviceId: this.deviceIds,
          id: userid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isRegisterDialog = true;

            this.polling = setInterval(() => {
              this.getEquipment(res.data);
            }, 2000);

            this.setIme = setTimeout(() => {
              if (this.polling) {
                this.closeRegister();
                this.$message.error("登记超时,请重新操作");
              }
            }, 40000);
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 登记操作
    checkIn(value, id) {
      var object = {
        id: id,
        deviceId: this.deviceIds,
        cmdType: "",
      };
      switch (value) {
        case 1:
          this.registerTitle = "人脸";
          object.cmdType = "FACE";
          break;
        case 3:
          this.registerTitle = "掌静脉";
          object.cmdType = "PALM";
          break;
      }

      http.post("/dmsDevice/cmd", object).then((res) => {
        if (res.code == 200) {
          this.isRegisterDialog = true;

          this.polling = setInterval(() => {
            this.getEquipment(res.data);
          }, 2000);

          this.setIme = setTimeout(() => {
            if (this.polling) {
              this.closeRegister();
              this.$message.error("登记超时,请重新操作");
            }
          }, 60000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 轮询设备信息
    getEquipment(id) {
      http
        .get("/dmsTask/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.status != 1) {
              this.closeOpertion();
            }
            switch (res.data.status) {
              case 0:
                this.$message.success("录入成功");
                break;
              case -1:
                this.$message.error("录入失败");
                break;
              case -2:
                this.$message.error("参数错误");
                break;
              case -3:
                this.$message.error("设备忙");
                break;
            }
          }
        });
    },
    // 移除操作
    removeOperation(type, id) {
      var typeString = "";
      switch (type) {
        case 1:
          typeString = "FACE_DELETE";
          break;
        case 2:
          typeString = "PALM_DELETE";
          break;
      }
      http
        .post("/dmsDevice/cmd", {
          deviceId: this.deviceIds,
          id: id,
          cmdType: typeString,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("移除成功");
            this.getDevicePeople({ pageNum: 1 });
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 移除指纹
    deletefp(index) {
      var string = "";
      switch (index) {
        case 0:
          string = "FP_DELETE";
          break;
        case 1:
          string = "FP_SECOND_DELETE";
          break;
        case 2:
          string = "FP_THIRD_DELETE";
          break;
      }

      http
        .post("/dmsDevice/cmd", {
          cmdType: string,
          id: this.currentManagement.umsUser.id,
          deviceId: this.deviceIds,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("移除成功");
            this.fpList[index] = "";
            this.getDevicePeople({ pageNum: 1 });
          } else {
            this.$message.error("移除成功");
          }
        });
    },

    // 结束操作并刷新
    closeOpertion() {
      this.isRegisterDialog = false;
      clearTimeout(this.polling);
      clearTimeout(this.setIme);
      this.polling = null;
      this.setIme = null;
      this.getDevicePeople({ pageNum: 1 });
    },

    // 结束登记操作
    closeRegister() {
      http
        .post("/dmsDevice/cancel", {
          id: this.deviceIds,
        })
        .then((res) => {
          if (res.code == 200) {
            this.adminId = null;
            clearInterval(this.polling);
            clearTimeout(this.setIme);
            this.polling = null;
            this.setIme = null;
            this.isRegisterDialog = false;
          }
        });
    },

    // 进行管理员操作
    openAdminDialog(value, id) {
      switch (value) {
        case 1:
          this.adminTitle = "设置为";
          break;
        case 2:
          this.adminTitle = "移除";
          break;
      }

      this.adminId = id;
      this.adminDialog = true;
    },

    // 取消管理员操作
    cancelAdminDialog() {
      this.adminId = null;
      this.adminDialog = false;
    },

    // 确认管理员操作
    comfinAdmin() {
      if (this.adminTitle == "移除") {
        http
          .post("/dmsDevice/remove_admin", {
            id: this.deviceIds,
            userId: this.adminId,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("移除成功");
              this.adminDialog = false;

              this.getDevicePeople({
                pageNum: 1,
              });
            } else {
              this.$message.error(res.message);
            }
          });
      } else {
        http
          .post("/dmsDevice/register_admin", {
            id: this.deviceIds,
            userId: this.adminId,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("设置成功");
              this.adminDialog = false;

              this.getDevicePeople({
                pageNum: 1,
              });
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },

    // 筛选设备状态
    filterStatus(value) {
      var arr = [];
      if (value == 1) {
        this.deviceListCopy.map((item) => {
          if (!item.isoffOnline) {
            arr.push(item);
          }
        });
        this.deviceList = arr;
        if (arr.length > 0) {
          this.deviceIds = arr[0].id;
          this.getDevicePeople({
            pageNum: 1,
          });
        } else {
          this.deviceIds = null;
          this.tableData = [];
        }
      } else if (value == 2) {
        this.deviceListCopy.map((item) => {
          if (item.isoffOnline) {
            arr.push(item);
          }
        });

        this.deviceList = arr;
        if (arr.length > 0) {
          this.deviceIds = arr[0].id;
          this.getDevicePeople({
            pageNum: 1,
          });
        } else {
          this.deviceIds = null;
          this.tableData = [];
        }
      } else {
        this.deviceList = this.deviceListCopy;
        if (this.deviceList.length > 0) {
          this.deviceIds = this.deviceList[0].id;
          this.getDevicePeople({
            pageNum: 1,
          });
        } else {
          this.deviceIds = null;
          this.tableData = [];
        }
      }
    },

    // 删除设备搜索
    clearDevice() {
      this.searchDeviceForm.likeString = "";
      delete this.searchDeviceForm.likeString;
      this.getDevice();
    },

    // 删除人员搜索
    clearPeople() {
      this.peopleSearchForm.likeString = "";
      delete this.peopleSearchForm.likeString;
      this.getDevicePeople({
        pageNum: 1,
      });
    },

    // 判断是否都是null
    isNull(value) {
      if (value) {
        var arr = value.split(",");
        const areAllEmptyOrNullStrings = arr.every(
          (item) => item === "" || item === "null"
        );
        return areAllEmptyOrNullStrings;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.checkin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.checkin_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.checkin_title span {
  color: #5c5c5c;
}
.checkin_information {
  width: 100%;
  flex: 1;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
}
.checkin_information::-webkit-scrollbar {
  display: none;
}
.checkin_information_t {
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 24px;
}
.member_header {
  width: 100%;
  height: 76px;
  border-bottom: 1px solid #cfd4e8;
  display: flex;
  align-items: center;
  padding: 0 19px;
  box-sizing: border-box;
  justify-content: flex-end;
}
.member_header_bto {
  margin-left: 16px;
  cursor: pointer;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  margin-left: 6px;
  text-align: center;
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 36px;
  font-size: 14px;
  color: #7e84a3;
}
.member_content {
  display: flex;
  flex: 1;
  width: 100%;
}
.member_content_l {
  width: 330px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px;
  border-right: 1px solid #cfd4e8;
  box-sizing: border-box;
}
.member_content_search {
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
}
.de_search_name {
  width: 290px !important;
  margin-right: 10px;
}

.de_search {
  width: 243px;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
::v-deep .member_content_search .el-select {
  width: 140px;
  margin-bottom: 16px;
}
.member_content_device {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}
.member_content_device::-webkit-scrollbar {
  display: none;
}
.member_content_d {
  width: 100%;
  height: 48px;
  padding: 0 40px;
  line-height: 48px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #5c5c5c;
  font-size: 16px;
  position: relative;
  cursor: pointer;
}
.member_content_d_online::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1fc700;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
.member_content_d_offline::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fd1d1d;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
.member_content_d_select {
  background: #f2f7ff;
}
.member_content_r {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 33px 0 44px;
  box-sizing: border-box;
}
.member_content_r_search {
  width: 100%;
  height: 86px;
  display: flex;
  padding-top: 20px;
  box-sizing: border-box;
}
::v-deep .member_content_r_search .el-select {
  width: 137px;
  margin: 0 12px;
}
::v-deep .member_content_r_search .el-input__icon {
  height: auto;
}
::v-deep .member_content_r_search .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 137px;
  height: 36px;
  margin-right: 12px;
}
.memeber_table {
  width: 100%;
  flex: 1;
  position: relative;
}
.member_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.m_table {
  width: 100%;
  height: 41rem;
  position: absolute;
}
.online_detection {
  width: 100px;
  height: 40px;
  background: #eaeaea;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  color: #1a1a1a;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}
.diglog_h {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.available_frame {
  width: 797px;
  height: 486px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  margin: 30px auto;
  display: flex;
}
.available_frame_left {
  width: 346px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_title {
  width: 100%;
  height: 45px;
  background: #ededed;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 45px;
  color: #1a1a1a;
  border-radius: 3px 0px 0px 0px;
}
.available_frame_center {
  width: 224px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_frame_right {
  flex: 1;
  height: 100%;
}
.available_search {
  width: 307px;
  margin: 15px auto 0;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  box-sizing: border-box;
}
.available_search_icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.available_search_input {
  height: 98%;
}
::v-deep .available_search .el-input__inner {
  height: 100%;
  border: none;
}
.available_depa {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.available_depa_left {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.available_depa_left_icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin-right: 10px;
}
.available_depa_left_icon2 {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin: 0 8px 0 0;
}
.available_depa_left_icon img {
  width: 18px;
  height: 18px;
}
.available_depa_left_icon2 img {
  width: 18px;
  height: 18px;
}
.available_depa_left_next {
  color: #0058ff;
  font-size: 15px;
  cursor: pointer;
}
.available_frame_tobeselected {
  margin-top: 12px;
}
.available_frame_tobeselected_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  box-sizing: border-box;
}
.available_frame_tobeselected_delte {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  text-align: right;
  cursor: pointer;
}
::v-deep .available_frame_tobeselected_top .el-checkbox__label {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
}
.available_frame_tobeselected_center {
  margin-top: 15px;
  height: 390px;
  overflow-y: scroll;
}
.available_frame_tobeselected_center::-webkit-scrollbar {
  display: none;
}
.available_frame_tobeselected_single {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_single2 {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_name {
  font-size: 15px;
  color: #1a1a1a;
}
.available_frame_tobeselected_depa {
  font-size: 13px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(26, 26, 26, 0.6);
}
.available_frame_select_left {
  display: flex;
  align-items: center;
}
.available_frame_select_right {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
::v-deep .workhour_time .el-form-item__content {
  line-height: normal;
}
.register_top {
  width: 100%;
  height: 73px;
  border-bottom: 1px solid #cfd4e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 18px;
  color: #1a1a1a;
  box-sizing: border-box;
}
.register_close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.register_center {
  margin-top: 33px;
}
.register_center_name {
  font-size: 16px;
  color: #1a1a1a;
  text-align: center;
}
.register_icon {
  width: 60px;
  height: 60px;
  margin: 21px auto 37px;
  display: block;
}
.register_end {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  margin: 0 auto;
  cursor: pointer;
}
.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settips {
  margin-top: 30px;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 16px;
  color: #1a1a1a;
}
.available_depa_select {
  background: #f2f7ff;
}
.breadcrumbs {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.breadcrumbs_text {
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs_current {
  color: #1a1a1a;
}
.breadcrumbs_last {
  color: rgba(26, 26, 26, 0.6);
}
.available_frame_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.available_frame_cancel {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  margin-right: 30px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.available_frame_comfin {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: white;
  text-align: center;
  cursor: pointer;
}
.available_depa_left_name {
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs_all {
  display: flex;
  align-items: center;
}
.structure_feature {
  display: flex;
  align-items: center;
}
.feature {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
::v-deep .available_search .el-select {
  width: 120px;
  border-right: 1px solid #d0d0d0;
}
.ellipsis {
  width: 130px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
.fp_list {
  display: flex;
  width: 100%;
  height: 203px;
  align-items: center;
  justify-content: center;
}
.fp_single {
  width: 98px;
  height: 138px;
  background: rgba(0, 137, 255, 0.07);
  border-radius: 4px 4px 4px 4px;
}
.fp_single:nth-child(2) {
  margin: 0 73px;
}
.fp_single {
  display: flex;
  position: relative;
  flex-direction: column;
}
.fp_single_top {
  height: 98px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 135, 252, 0.14);
}
.fp_single_add {
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.fp_single_text {
  flex: 1;
  line-height: 40px;
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.offline {
  position: relative;
}
.offline::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  width: 8px;
  height: 8px;
  background: #ff0000;
  border-radius: 50%;
}
.online {
  position: relative;
}
.online::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  width: 8px;
  height: 8px;
  background: #1ab137;
  border-radius: 50%;
}
.fp_single_icon {
  width: 58px;
  height: 58px;
}
.available_depaall {
  height: 345px;
  overflow-y: scroll;
}
.available_depaall::-webkit-scrollbar {
  display: none;
}
.deleteFp {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.newpagination {
  height: 50px;
  margin-top: 10px;
}
</style>
